import React from 'react';
import ReactDOM from 'react-dom';
import { hot } from 'react-hot-loader/root';
import WebFont from 'webfontloader';
import './assets/css/index.css';
import './assets/css/normalize.css';
import ReactGA from 'react-ga4';
import App from './pages';

ReactGA.initialize('G-70SWC0HG53');

const WithHotReload = process.env.NODE_ENV === 'development' ? hot(App) : App;

WebFont.load({
  google: {
    families: ['Open Sans:300,400,700', 'sans-serif'],
  },
});

ReactDOM.render(
  <React.StrictMode>
    <WithHotReload />
  </React.StrictMode>,
  document.getElementById('root')
);
