import PrivateRouter from 'helpers/PrivateRoute';
import React, { lazy, Suspense } from 'react';
import { Grid } from 'react-flexbox-grid';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Theme from '../Theme';

const Home = lazy(() => import('./Home'));
const Auth = lazy(() => import('./Auth'));
const Autorizacao = lazy(() => import('./Autorizacao'));
const AtualizarImovel = lazy(() => import('./AtualizarImovel'));
const NaoAutorizado = lazy(() => import('./NaoAutorizado'));
const ObrigadoAutorizacao = lazy(() => import('./Autorizacao/ObrigadoAutorizacao'));
const ObrigadoAtualizacao = lazy(() => import('./AtualizarImovel/ObrigadoAtualizacao'));
const Relatorios = lazy(() => import('./Relatorios'));

function App() {
  return (
    <Router>
      <Suspense fallback={<div>Carregando...</div>}>
        <Theme>
          <div
            style={{
              maxWidth: 1024,
              margin: '0 auto',
              backgroundColor: '#ffffff',
            }}
          >
            <Grid>
              <Switch>
                <Route exact path="/">
                  <Home />
                </Route>
                <Route path="/auth">
                  <Auth />
                </Route>
                <Route path="/relatorios">
                  <Relatorios />
                </Route>
                <Route path="/404-nao-encontrado">
                  <NaoAutorizado />
                </Route>
                <PrivateRouter path="/autorizacao/obrigado">
                  <ObrigadoAutorizacao />
                </PrivateRouter>
                <PrivateRouter path="/atualizacao/obrigado">
                  <ObrigadoAtualizacao />
                </PrivateRouter>
                <PrivateRouter path="/autorizacao">
                  <Autorizacao />
                </PrivateRouter>
                <PrivateRouter path="/atualizacao">
                  <AtualizarImovel />
                </PrivateRouter>
              </Switch>
            </Grid>
          </div>
        </Theme>
      </Suspense>
    </Router>
  );
}

export default App;
