import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { isAuthenticated, isNewToken } from 'storage/auth';

export default function PrivateRouter({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => {
        const { location } = props;
        const hasToken = location.search.indexOf('token=') !== -1;
        const token = hasToken ? location.search.slice(7) : '';
        if (!isAuthenticated() || isNewToken(token))
          return <Redirect to={{ pathname: '/auth', state: { from: props.location } }} />;
        return children;
      }}
    />
  );
}
