import React from 'react';
import { ThemeProvider } from 'styled-components';

const theme = {
  colors: {
    powderWhite: '#FFFDF9',
    primary: '#6c1027',
    secondary: '#400b21',
    tertiary: '#511125',
    onyx: '#484848',
    disabled: 'rgb(222,222,226)',
  },
  fonts: ['sans-serif', 'Open Sans'],
  fontSizes: {
    small: '1em',
    medium: '2em',
    large: '3em',
  },
};

function Theme({ children }) {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}

export default Theme;
