export const TOKEN_KEY = '@token';

export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;

export const isNewToken = (token) => token && token !== '' && localStorage.getItem(TOKEN_KEY) !== token;

export const getToken = () => localStorage.getItem(TOKEN_KEY);

export const login = (token) => {
  if (token) localStorage.setItem(TOKEN_KEY, token);
};

export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
};
